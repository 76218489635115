import React from 'react'
import styled from 'styled-components'
import {graphql} from 'gatsby'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import Container from './../components/container'
import PageHeading from './../components/page-heading'
import BodyCopy from './../components/body-copy'
import {stripMarkup} from './../helper-functions/strip-markup'

export default props => {
  const {data} = props
  const prismicData = {
    pageHeading: data.prismicContactPage.data.page_heading.text,
    bodyCopy: data.prismicContactPage.data.body_copy.html,
  }

  return (
    <Layout pageName="enquiries" documentTitlePrefix={prismicData.pageHeading}>
      <PageHeading heading={prismicData.pageHeading} />
      {stripMarkup(prismicData.bodyCopy) && (
        <Container doublePadding>
          <BodyCopy bodyHtml={prismicData.bodyCopy} classModifier="enquiries" />
        </Container>
      )}
      <Container doublePadding>
        <Fade>
          <StyledForm
            name="Fred Cuming Website Enquiries Form"
            method="POST"
            action="/thanks/"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="Fred Cuming Website Enquiries Form" />
            <input type="hidden" name="bot-field" />
            <div className="form-row form-row--name-email">
              <div className="form-item form-item--name">
                <label className="form-item__label" htmlFor="name">
                  Name
                </label>
                <input className="form-item__input" type="text" id="name" name="name" required />
              </div>
              <div className="form-item form-item--email">
                <label className="form-item__label" htmlFor="email">
                  Email
                </label>
                <input className="form-item__input" type="email" id="email" name="email" required />
              </div>
            </div>
            <div className="form-row form-row--telephone-message">
              <div className="form-item form-item--telephone">
                <label className="form-item__label" htmlFor="email">
                  Telephone
                </label>
                <input className="form-item__input" type="text" id="telephone" name="telephone" placeholder="OPTIONAL" />
              </div>
              <div className="form-item form-item--message">
                <label className="form-item__label" htmlFor="message">
                  Message
                </label>
                <textarea className="form-item__textarea" id="message" name="message" required />
              </div>
            </div>
            <div className="form-row form-row--submit">
              <button type="submit" className="btn btn--primary">
                Send
              </button>
            </div>
          </StyledForm>
        </Fade>
        <div className="representation">
          <Fade>
            <StyledRepresentation>
              <div className="representation-wrapper">
                <div className="representation-left">
                  <h3>Contact:</h3>
                </div>
                <div className="representation-right">
                  <p>
                    Fred Cuming is also represented by the <span className="gallery-name">Adam Gallery</span> and{' '}
                    <span className="gallery-name">The Royal Academy</span>.
                  </p>
                  <div className="link-wrapper">
                    <a href="http://www.adamgallery.com/bath/contact-us" target="_blank" rel="noopener" className="btn">
                      Adam Gallery
                    </a>
                    <a
                      href="https://www.royalacademy.org.uk/art-artists/name/frederick-cuming-ra"
                      target="_blank"
                      rel="noopener"
                      className="btn"
                    >
                      Royal Academy
                    </a>
                  </div>
                </div>
              </div>
            </StyledRepresentation>
          </Fade>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prismicContactPage {
      data {
        page_heading {
          text
        }
        body_copy {
          html
        }
      }
    }
  }
`

const StyledRepresentation = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1100px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -160px;
    max-width: 1300px;
  }

  @media (min-width: 1470px) {
    margin-top: -113px;
  }

  .representation-wrapper {
    @media (min-width: 1100px) {
      width: calc(50% - 3rem);
    }

    @media (min-width: 1470px) {
      display: flex;
      flex-direction: row;
    }
  }

  .representation-left {
    @media (min-width: 1470px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 2rem;
    }
  }

  .representation-right {
    @media (min-width: 1470px) {
      width: 100%;
    }
  }

  h3 {
    font-size: 13.9pt;
    font-weight: 200;
    margin: 0 0 2rem 0;
    text-transform: uppercase;

    @media (min-width: 1470px) {
      margin-bottom: 7px;
    }
  }

  p {
    font-weight: 600;
    margin: 0 0 2rem 0;
    max-width: 38.5rem;
  }

  .gallery-name {
    text-transform: uppercase;
  }

  .link-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 51rem;

    @media (min-width: 560px) {
      flex-direction: row;
    }

    @media (min-width: 1100px) {
      max-width: none;
    }

    a {
      display: block;
      margin-bottom: 2rem;
      margin-right: 0;
      text-align: center;
      width: 100%;

      @media (min-width: 560px) {
        margin-bottom: 0;
        margin-right: 2rem;
      }

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }
`

const StyledForm = styled.form`
  margin-bottom: 5rem;

  @media (min-width: 1100px) {
    margin-bottom: 0;
  }

  .form-item {
    @media (min-width: 800px) {
      width: 100%;
    }

    &--name,
    &--message {
      @media (min-width: 800px) {
        margin-right: 6rem;
      }
    }

    &--name,
    &--telephone {
      margin-bottom: 3rem;
    }

    &__label {
      border-bottom: 2px solid #909090;
      display: block;
      font-weight: 200;
      font-size: 13.9pt;
      margin-bottom: 1.5rem;
      max-width: 30rem;
      padding-bottom: 1rem;
      text-transform: uppercase;
    }

    &__input {
      border: 0;
      background-color: #d9d9d9;
      font-size: 13.9pt;
    }

    &__textarea {
      border: 0;
      background-color: #d9d9d9;
      font-size: 13.9pt;
      height: 20.5rem;
      max-height: 70rem;
      min-height: 35rem;
      height: 35rem;
      resize: vertical;
    }
  }

  .form-row {
    max-width: 1300px;

    @media (min-width: 800px) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    &--telephone-message {
      @media (min-width: 800px) {
        flex-direction: row-reverse;
      }
    }

    &--submit {
      @media (min-width: 600px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      button {
        display: block;
        min-width: 11rem;
        width: 100%;

        @media (min-width: 600px) {
          width: auto;
        }
      }
    }
  }
`
